import { Link } from 'gatsby'
import React from 'react'
import packageVersion from '../../package.json'
import StatePartnerLogo from '../components/statePartnerLogo'
import GlobalStateContext from '../context/globalStateContext'
import AccessibilityLogo from '../svg/accessible.svg'
import AcisLogo from '../svg/acis-logo.svg'
// Logos
import CornellLogo from '../svg/cornell-logo.svg'
import IpmLogo from '../svg/ipm-logo.svg'
import UsdaLogo from '../svg/usda-logo.svg'
import { determineStatePartner } from '../utils/helpers'

const { version } = packageVersion
const linkStyle =
  'border-b-2 border-transparent lg:mb-0 hover:border-white text-white hover:text-white active:text-black active:border-black no-underline'

export default function FooterSection({ color, station }) {
  const { DEFAULT_STATION } = React.useContext(GlobalStateContext)
  const [statePartner, setStatePartner] = React.useState(null)

  React.useEffect(() => {
    if (station) {
      const ss = determineStatePartner(station)
      if (ss) {
        setStatePartner(ss)
      } else {
        setStatePartner(determineStatePartner(DEFAULT_STATION))
      }
    }
  }, [station, DEFAULT_STATION])
  return (
    <footer className="bg-white">
      <section aria-label="Disclaimer" className="pb-12 bg-gray-50">
        <div className="container w-4/5 py-4 mx-auto">
          <h2 className="flex justify-center text-3xl font-bold tracking-wider text-center md:text-4xl">
            Disclaimer
          </h2>
          <p className="m-auto mt-4 text-base leading-relaxed text-justify">
            Accuracy of the weather data is the responsibility of the owners of
            the weather station instruments. NEWA is not responsible for
            accuracy of the weather data collected by instruments in the
            network. If you notice erroneous or missing weather data,
            <a href="mailto:support@newa.zendesk.com" className="ml-1">
              contact NEWA
            </a>{' '}
            and we will contact the owner of the instrument.{' '}
            <strong>
              In no event shall Cornell University or any weather station be
              liable to any party for direct, indirect, special, incidental, or
              consequential damages, including lost profits, arising out of the
              use of NEWA.
            </strong>
          </p>
        </div>
      </section>

      <nav
        aria-label="Footer Menu"
        className={`w-full ${color} sm:rounded-t-md`}
      >
        <div className="max-w-sm md:max-w-md mx-auto py-6 sm:py-8">
          <div className="text-center grid grid-rows-4 sm:grid-rows-2 sm:grid-cols-2 grid-flow-col gap-6 sm:gap-8 text-lg sm:text-xl md:text-2xl">
            <Link to="/partners" className={linkStyle}>
              Partners
            </Link>
            <Link to="/become-partner" className={linkStyle}>
              Become a Partner
            </Link>
            <Link to="/about-us" className={linkStyle}>
              About Us
            </Link>
            <Link to="/press-room" className={linkStyle}>
              Press Room
            </Link>
          </div>
        </div>
      </nav>

      <section aria-label="Partners" className="bg-white">
        <div className="mx-auto my-12 max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="flex flex-col flex-wrap items-center justify-center">
            <div className="mb-14">
              <h2 className="text-3xl font-bold">Brought to You By</h2>
            </div>

            <div className="grid w-full grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
              {/* CORNELL */}
              <span className="w-64 justify-self-center">
                <a
                  href="https://www.cornell.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red-500"
                >
                  <CornellLogo alt="Cornell University"></CornellLogo>
                </a>
              </span>

              {/* IPM */}
              <span className="w-64 justify-self-center">
                <a
                  href="https://nysipm.cornell.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fill-current"
                >
                  <IpmLogo alt="New York State Integrated Pest Management Program"></IpmLogo>
                </a>
              </span>

              {/* ACIS */}
              <span className="w-64 justify-self-center">
                <a
                  href="http://www.nrcc.cornell.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fill-current"
                >
                  <AcisLogo alt="Northeast Regional Climate Center"></AcisLogo>
                </a>
              </span>

              {/* USDA */}
              <span className="w-64 justify-self-center">
                <a
                  href="https://nifa.usda.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fill-current"
                >
                  <UsdaLogo alt="United State Department of Agriculture, National Institute of Food and Agriculture"></UsdaLogo>
                </a>
              </span>

              {/* NEWA State Partner */}
              {statePartner !== null && (
                <span className="w-64 justify-self-center">
                  <StatePartnerLogo
                    newaStatePartner={statePartner}
                    smallLogo={false}
                  ></StatePartnerLogo>
                </span>
              )}
            </div>
          </div>
        </div>
      </section>

      <section
        aria-label="Copyright and Accessibility"
        className="px-4 bg-gray-50 md:px-0"
      >
        <div className="px-4 py-8 mx-auto max-w-screen-2xl">
          <div className="flex flex-col items-center justify-center md:max-w-2xl md:mx-auto md:flex-row">
            <AccessibilityLogo className="w-12 h-12 mr-2 text-red-700 md:w-20 md:h-20"></AccessibilityLogo>
            <p className="text-center md:text-left">
              If you have a disability and are having trouble accessing
              information on this website or need materials in an alternate
              format, contact{' '}
              <a href="mailto:web-accessibility@cornell.edu">
                web-accessibility@cornell.edu
              </a>{' '}
              for assistance.
            </p>
          </div>

          <div className="flex flex-col items-center justify-center pb-4 mt-6 text-sm md:flex-row">
            <div>
              © 1996-{new Date().getFullYear()} <a href="https://cals.cornell.edu/new-york-state-integrated-pest-management" target="_blank" rel="noreferrer">New York State Integrated Pest Management</a> | <a href="https://cals.cornell.edu" target="_blank" rel="noreferrer">College of Agriculture and Life Sciences at Cornell University</a>
            </div>
          </div>
          <div className="pb-4 font-bold text-center">Version: {version}</div>
        </div>
      </section>
    </footer>
  )
}
