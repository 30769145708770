import { Link } from "gatsby";
import React from "react";
import GlobalStateContext from "../context/globalStateContext";
import { useAuth0 } from "../context/react-auth0-spa";
import { determineStatePartner } from "../utils/helpers";

const SubHeader = ({ station, h1Text }) => {
  const { isAuthenticated } = useAuth0();
  const { DEFAULT_STATION } = React.useContext(GlobalStateContext);
  const [statePartner, setStatePartner] = React.useState(null);
  const defaultAffiliation = "Cornell University";
  const defaultAffiliationUrl = "https://cals.cornell.edu";
  const affiliation =
    statePartner &&
    statePartner.affiliation !== null &&
    statePartner.affiliation !== "Cornell University"
      ? statePartner.affiliation
      : defaultAffiliation;
  const affiliationUrl =
    statePartner &&
    statePartner.affiliationUrl !== null &&
    statePartner.affiliation !== "Cornell University"
      ? statePartner.affiliationUrl
      : defaultAffiliationUrl;

  console.log("state partner:", statePartner);

  React.useEffect(() => {
    if (station) {
      const ss = determineStatePartner(station);
      if (ss) {
        setStatePartner(ss);
      } else {
        // Set statePartner to default values if DEFAULT_STATION is not available yet
        setStatePartner({
          affiliation: defaultAffiliation,
          affiliationUrl: defaultAffiliationUrl,
        });
      }
    }
  }, [station, DEFAULT_STATION]);

  const heading1Text = h1Text || "";
  // Style --------------------------------------------------------------------------
  const linkStyle = `text-gray-800 hover:text-gray-900 hover:border-gray-900 md:text-lg lg:text-xl font-extrabold md:font-bold  py-2 px-0 hover:border-b-2 whitespace-no-wrap`;
  const activeStyle = `text-primary-900 border-primary-900 border-b-2`;

  return (
    <div>
      <div className="container items-center justify-center max-w-full mx-auto text-gray-700 no-underline md:flex lg:px-6 container-nav sm:max-w-full md:max-w-screen-md lg:max-w-screen-lg md:px-3 xl:px-12">
        <h1 className="px-2 mt-6 text-3xl font-bold leading-none tracking-wider sm:text-4xl md:text-5xl text-center">
          {heading1Text}
        </h1>
      </div>
      <header className="px-4 ml-4 mr-4 mt-2 mb-6 items-center justify-center max-w-full mx-auto text-gray-700 no-underline">
        <p className="text-center">
          <small>
            NEWA is a partnership of{" "}
            <a
              href="https://cals.cornell.edu/new-york-state-integrated-pest-management"
              target="_blank"
              rel="noopener noreferrer"
            >
              New York State Integrated Pest Management
            </a>
            ,{" "}
            <a
              href="https://www.nrcc.cornell.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Northeast Regional Climate Center
            </a>
            , and{" "}
            <a href={affiliationUrl} target="_blank" rel="noopener noreferrer">
              {affiliation}
            </a>
          </small>
        </p>
      </header>
    </div>
  );
};

export default SubHeader;
