import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const StatePartnerLogo = ({
  newaStatePartner,
  smallLogo = true,
  isModel = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "statePartnersLogos" } }) {
        edges {
          node {
            base
            publicURL
          }
        }
      }
    }
  `)

  let logo
  if (newaStatePartner.isNewaStatePartner) {
    if (smallLogo) {
      logo = data.allFile.edges.find(
        ({ node }) => node.base === newaStatePartner.srcLogoSmall.slice(1),
      )
    } else {
      logo = data.allFile.edges.find(
        ({ node }) => node.base === newaStatePartner.srcLogo.slice(1),
      )
    }
  }

  return (
    <a
      href={newaStatePartner.affiliationUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={
        smallLogo ? `inline-block ${isModel ? `w-11` : 'w-10 lg:w-16'}` : ``
      }
      aria-label={newaStatePartner.affiliation}
    >
      {logo && <img src={logo.node.publicURL} alt={newaStatePartner.altText} />}
    </a>
  )
}

export default StatePartnerLogo
